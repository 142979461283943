@import './payment-methods/apple-pay/apple-pay';

.word-break-all {
  word-break: break-all !important;
}

// TODO: Update edx-bootstrap theme to incorporate these edits.
.btn, a.btn {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
.btn-link {
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  & > main {
    flex-grow: 1;
  }
}

@keyframes skeleton-pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.page__payment {
  .col-basket-summary {
    @include media-breakpoint-up(md) {
      border-right: solid 1px $border-color;
    }
  }
  .product-thumbnail {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  .basket-section {
    @extend .mb-5;
  }
  .summary-row {
    margin-bottom: map-get($spacers, 3);
  }
  .summary-row {
    margin-bottom: map-get($spacers, 3);
  }
  label {
    font-weight: normal;
  }
  .form-group {
    .card-icon {
      position: relative;
      float: right;
      top: -27px;
      right: 28%;
      margin-right: -15%;
      pointer-events: none;
    }
    .lock-icon {
      position: relative;
      float: right;
      top: -27px;
      right: 10%;
      margin-right: -5%;
      pointer-events: none;
    }
  }
  .payment-method-button {
    display: inline-block;
    background-color: white;
    padding: .5rem;
    height: 2.75rem;
    margin-right: map-get($spacers, 2);
    margin-bottom: map-get($spacers, 2);
    border: solid $border-width $border-color;
    border-radius: 0;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    &[disabled] {
      cursor: auto;
      filter: grayscale(100%);
    }
    &.active {
      border-bottom: solid 0.1875rem $primary;
    }
    img {
      height: 1.5rem;
      display: block;
    }
  }

  .skeleton-pulse {
    animation: skeleton-pulse 2s infinite both;
  }
  .skeleton {
    background-color: rgba(0,0,0, .1);
    animation: skeleton-pulse 2s infinite both;
  }

  .button-spinner-icon {
    @extend .spinner-border;
    @extend .spinner-border-sm;
    color: white;
    line-height: 1;
    font-size: 1rem;
    display: block;
  }

  #purchasedForOrganization {
    width: 18px;
    height: 18px;
  }

  #couponField {
    width: 11rem;
  }

  .num-enrolled {
    display: inline-block;
    background-color: #d8edf8;
    padding: 0.1rem 0.5rem;
    margin: 0.2rem 0rem;
  }

  .price-messaging {
    background: #eff8fa;
    border: 1px solid #BBDFF2;
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 1.5;
    margin: 20px auto;
    padding: 20px;
  }

  .price-messaging p {
    margin-bottom: 2px;
  }

  .flex-microform {
    @extend .form-control;
  }

  .flex-microform-focused {
    @extend .form-control, :focus;
  }

  .flex-microform-disabled {
    @extend .form-control, :disabled;
  }

  .microform-label {
    margin-bottom: 0.5rem;
    display: inline-block;
  }
}

#securityCodeHelp {
  width: 250px;
}
