.apple-pay-button {
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;

  .apple-pay-button-white {
    -apple-pay-button-style: white;
  }

  .apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
  }
}
